<template>
  <div>
    <vue-excel-xlsx
      class="btn btn-success font-weight-bold w-100 mb-3"
      :data="data"
      :columns="columns"
      :file-name="nameCourse"
      :file-type="'xlsx'"
      :sheet-name="'ASISTENCIA'"
    >
      <b-icon-file-earmark-excel /> Reporte de asistencia
    </vue-excel-xlsx>
  </div>
</template>

<script>
export default {
  props: {
    idCourse: {
      type: String,
      default: null,
    },
    nameCourse: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      columns: [],
      data: [],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    priceFormat(value) {
      if (value == null) return "-";
      else return value;
    },
    async initialize() {
      this.columns = [];
      this.$parent.selected = "circular";
      await this.$axios
        .get("/dashboard/course/" + this.idCourse + "/assistance-report", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.data = res.data.allAssistancePersonDto;
          this.addHeader(res.data.allDate);
          this.$parent.selected = "";
        })
        .catch((error) => {
          this.$message.error(error.response.data.content);
        });
    },
    addHeader(values) {
      this.columns.push(
        {
          label: "APELLIDO PATERNO",
          field: "lastnameFather",
        },
        {
          label: "APELLIDO MATERNO",
          field: "lastnameMother",
        },
        {
          label: "NOMBRES",
          field: "name",
        },
      );
      for (const value in values) {
        this.columns.push({
          label: values[value],
          field: "allAssistance[" + value + "].status",
          dataFormat: this.priceFormat,
        });
      }
    },
  },
};
</script>