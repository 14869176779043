<template>
  <div id="ListAssistsStatistics">
    <div>
      <b-modal
        scrollable
        centered
        v-model="show"
        :title="'Estadísticas de asistencia ' + dateText"
        ok-only
      >
        <b-container fluid>
          <!--Contenido modal-->
          <Bar v-if="loaded" :chart-data="chartData" />
        </b-container>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "ListAssistsStatistics",
  components: { Bar },
  props: {
    idCourse: {
      type: String,
      default: null,
    },
    idDate: {
      type: String,
      default: null,
    },
    dateText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loaded: false,
      show: false,
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Cantidad",
            data: null,
            backgroundColor: [
              "rgba(75, 192, 192, 0.2)",
              "rgba(255, 99, 132, 0.2)",
              "rgb(180, 180, 180, 0.2)",
              "rgba(255, 206, 86, 0.2)",
            ],
            borderColor: [
              "rgb(60, 179, 113)",
              "rgba(255, 99, 132, 1)",
              "rgb(120, 120, 120)",
              "rgba(255, 206, 86, 1)",
            ],
            borderWidth: 3,
          },
        ],
      },
    };
  },
  methods: {
    async getStatistics() {
      this.loaded = false;
      this.chartData.labels = [];
      this.chartData.datasets[0].data = [];
      this.show = true;
      await this.$axios
        .get(
          "/dashboard/course/" +
            this.idCourse +
            "/assistance-stats?dateId=" +
            this.idDate,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          for (var value of res.data) {
            this.chartData.labels.push(
              value.name + " (" + value.quantity + ")"
            );
            this.chartData.datasets[0].data.push(parseInt(value.quantity));
          }
          this.loaded = true;
        })
        .catch((e) => {
          console.log(e);
          this.$errorQuery(e);
        });
    },
    exit() {
      this.show = false;
    },
  },
};
</script>
<style>
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>