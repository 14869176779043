<template>
  <div>
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="card">
      <div class="card-header header-blue">
        <h1><b-icon-calendar4-week /> Asistencia de {{ nameCourse }}</h1>
      </div>
      <div class="card-body">
        <b-row class="mb-0">
          <b-col lg="6">
            <ReportAssists
              :idCourse="idCourse"
              :nameCourse="nameCourse+'_ReporteAsistencia'"
            />
          </b-col>
          <b-col lg="6">
            <button
              v-if="!generateDates"
              @click="openAssists()"
              style="background-color: #5e151d; color: white"
              class="btn mb-3 font-weight-bold w-100"
            >
              Aperturar lista de asistencia
            </button>
            <button
              @click="openAssists()"
              style="background-color: #5e151d; color: white"
              class="btn mb-3 font-weight-bold w-100"
              v-else
            >
              Actualizar lista de asistencia
            </button>
          </b-col>
        </b-row>
        <div v-show="generateDates">
          <b-alert v-show="value == ''" show variant="info">
            <h4 class="alert-heading">Indicaciones</h4>
            <p class="mb-0">
              <b-icon-caret-right-fill /> Los circulos de colores son las fechas
              registradas para el curso.
            </p>
            <p class="mb-0">
              <b-icon-caret-right-fill /> La fecha borde azul indica la fecha
              actual.
            </p>
            <p class="mb-0">
              <b-icon-caret-right-fill /> Al seleccionar una fecha marcada se
              desplegara la asistencia.
            </p>
            <p class="mb-0">
              <b-icon-caret-right-fill /> Las fechas en verde indican que ya se
              tiene un registro de asistencia y las fechas de plomo indican que
              no se tiene ninguna asistencia registrada.
            </p>
          </b-alert>
          <v-date-picker
            :attributes="attrs"
            v-model="value"
            :model-config="modelConfig"
            is-expanded
            @dayclick="queryAssists()"
          />
          <b-alert
            v-show="value != ''"
            class="mt-3 py-0"
            show
            variant="primary"
          >
            <h4 class="alert-heading text-center">
              <b>{{ value }}</b>
            </h4>
          </b-alert>
          <div v-show="value != ''">
            <b-row class="mt-3">
              <b-col lg="4">
                <button
                  v-if="!disabledAttendance"
                  @click="disabledAttendance = !disabledAttendance"
                  style="background-color: #1d425e; color: white"
                  class="btn mb-3 font-weight-bold w-100"
                >
                  Ingresar asistencias
                </button>
                <button
                  v-else
                  @click="endAttendance()"
                  style="background-color: #5e151d; color: white"
                  class="btn mb-3 font-weight-bold"
                >
                  Finalizar ingreso de asistencias
                </button></b-col
              >
              <b-col lg="3">
                <b-button
                  v-if="!disabledAttendance"
                  @click="$refs.statistics.getStatistics()"
                  class="mb-3 font-weight-bold w-100"
                >
                  Estadísticas
                </b-button>
              </b-col>
              <b-col lg="5" class="mb-3">
                <RegisterBlock
                  v-if="value != ''"
                  :options="optionsAttendance"
                  :idDetail="hashmap.get(value) + ''"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4 mb-3">
                <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input-group-prepend is-text>
                    <b-icon icon="search"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="keyword"
                    placeholder="Buscar"
                    type="text"
                  />
                </b-input-group>
              </b-col>
            </b-row>
            <b-table
              style="background-color: white"
              :no-border-collapse="noCollapse"
              small
              striped
              outlined
              responsive
              :items="itemsFilter"
              :fields="fields"
              :keyword="keyword"
            >
              <!--Modificaciones celdas-->
              <template #cell(index)="row">
                {{ row.index + 1 }}
              </template>
              <template #cell(name)="row">
                <p>
                  {{
                    row.item.lastnameFather +
                    " " +
                    row.item.lastnameMother +
                    ", " +
                    row.item.name
                  }}
                </p>
              </template>
              <template #cell(assistStatus)="row">
                <div v-if="disabledAttendance">
                  <b-form-radio-group
                    v-model="row.item.assistStatus"
                    :options="optionsAttendance"
                    name="radio-btn-outline"
                    :button-variant="
                      'outline-' + colorsListHashmap.get(row.item.assistStatus)
                    "
                    @change="queryAssistsPerson(row.item)"
                    buttons
                  ></b-form-radio-group>
                </div>
                <div v-else>
                  <h5>
                    <b-badge
                      pill
                      class="w-100"
                      :variant="colorsHashmap.get(row.item.assistStatus)"
                    >
                      {{ namesHashmap.get(row.item.assistStatus) }}
                    </b-badge>
                  </h5>
                </div>
              </template>
            </b-table>
            <div v-show="keyword == ''">
              <button
                v-show="disabledAttendance"
                @click="endAttendance()"
                style="background-color: #5e151d; color: white"
                class="btn mb-3 font-weight-bold"
              >
                Finalizar ingreso de asistencias
              </button>
            </div>
            <ListAssistsStatistics
              v-if="value != ''"
              :idCourse="idCourse + ''"
              :idDate="hashmap.get(value) + ''"
              :dateText="value"
              ref="statistics"
            />
            <!--<p>{{ items }}</p>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListAssistsStatistics from "./Course-ListAssists-Statistics.vue";
import RegisterBlock from "./Course-ListAssists-RegisterBlock.vue";
import ReportAssists from "./Course-ListAssists-Report.vue";
export default {
  components: {
    ListAssistsStatistics,
    RegisterBlock,
    ReportAssists,
  },
  props: {
    idCourse: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      nameCourse: "",
      keyword: "",
      selected: "",
      colorsListHashmap: null,
      colorsHashmap: null,
      namesHashmap: null,
      hashmap: null,
      generateDates: null,
      dataDate: [],
      attrs: [],
      //ojo
      value: "",
      modelConfig: {
        type: "string",
        mask: "D-M-YYYY", // Uses 'iso' if missing "YYYY-MM-DD"
      },
      stickyHeader: true,
      noCollapse: false,
      disabledAttendance: false,
      optionsAttendance: [],
      fields: [
        {
          key: "index",
          isRowHeader: true,
          label: "#",
        },
        {
          key: "name",
          isRowHeader: true,
          label: "Estudiante",
        },
        {
          key: "assistStatus",
          label: "Asistencia",
        },
      ],
      items: [],
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    itemsFilter() {
      return this.keyword
        ? this.items.filter(
            (item) =>
              item.name.toLowerCase().includes(this.keyword.toLowerCase()) ||
              item.lastnameFather
                .toLowerCase()
                .includes(this.keyword.toLowerCase()) ||
              item.lastnameMother
                .toLowerCase()
                .includes(this.keyword.toLowerCase())
          )
        : this.items;
    },
  },
  methods: {
    endAttendance() {
      this.disabledAttendance = !this.disabledAttendance;
      this.keyword = "";
      this.initialize();
      this.$refs.statistics.getStatistics();
    },
    async queryAssistsPerson(data) {
      await this.$axios
        .post(
          "/dashboard/course/" + this.idCourse + "/assistance-register",
          {
            personId: data.id,
            horaryDetailId: this.hashmap.get(this.value),
            assistanceStatusId: data.assistStatus,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data.content);
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async openAssists() {
      this.selected = "circular";
      await this.$axios
        .get("/dashboard/course/" + this.idCourse + "/generate-assists", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.$message.success(res.data.content);
          this.initialize();
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    //Formatear impresion de fechas y formateo de fechas hashmap
    formatDataDate() {
      this.hashmap = new Map();
      for (let index = 0; index < this.dataDate.length; index++) {
        var color = null;
        if (this.dataDate[index].containRegisters) {
          color = "green";
        } else {
          color = "gray";
        }
        var object = {
          key: index,
          highlight: color,
          dates: new Date(
            this.dataDate[index].year,
            this.dataDate[index].month,
            this.dataDate[index].day
          ),
        };
        this.attrs.push(object);
        //datos para hashmap
        this.hashmap.set(
          this.dataDate[index].day +
            "-" +
            (this.dataDate[index].month + 1) +
            "-" +
            this.dataDate[index].year,
          this.dataDate[index].id
        );
      }
      this.attrs.push({
        highlight: {
          fillMode: "outline",
        },
        dates: new Date(),
      });
    },
    formatOptions(data) {
      this.colorsListHashmap = new Map();
      this.colorsHashmap = new Map();
      this.namesHashmap = new Map();
      for (let index = 0; index < data.length; index++) {
        this.optionsAttendance.push({
          text: data[index].name,
          value: data[index].id,
        });
        this.colorsListHashmap.set(data[index].id, data[index].color);
        this.colorsHashmap.set(data[index].id, data[index].color);
        this.namesHashmap.set(data[index].id, data[index].name);
      }
      this.colorsListHashmap.set(null, "dark");
      this.colorsHashmap.set(null, "white");
      this.namesHashmap.set(null, "Sin asistencia");
    },
    async queryAssists() {
      this.items = [];
      this.optionsAttendance = [];
      this.disabledAttendance = false;
      if (this.value != "") {
        if (this.hashmap.has(this.value)) {
          this.selected = "circular";
          await this.$axios
            .get(
              "/dashboard/course/" +
                this.idCourse +
                "/list-assists?dateId=" +
                this.hashmap.get(this.value),
              {
                headers: {
                  Authorization: `Bearer ${localStorage.token}`,
                },
              }
            )
            .then((res) => {
              this.items = res.data.allPersonAssist;
              this.formatOptions(res.data.allAssistStatus);
              this.selected = "";
            })
            .catch((error) => {
              this.$errorQuery(error);
            });
        } else {
          this.value = "";
        }
      }
    },
    async initialize() {
      this.attrs = [];
      this.selected = "circular";
      await this.$axios
        .get("/dashboard/course/" + this.idCourse + "/list-assists", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.nameCourse = res.data.course;
          this.dataDate = res.data.allHoraryDate;
          this.generateDates = res.data.containAssists;
          this.formatDataDate();
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>
<style scoped>
.header-blue {
  background-color: #1d425e;
  color: white;
}
</style>