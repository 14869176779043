<template>
  <div id="RegisterBlock">
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="loader"
      disableScrolling="false"
    ></loader>
    <b-input-group>
      <b-form-select v-model="selected" :options="options"></b-form-select>
      <b-input-group-append>
        <b-button class="font-weight-bold" @click="inquiry()">Registro en bloque</b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: "RegisterBlock",
  props: {
    options: {
      type: Array,
      default: null,
    },
    idDetail: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selected: null,
      loader: "",
    };
  },
  methods: {
    async inquiry() {
      this.loader = "circular";
      if (this.selected != null) {
        await this.$axios
          .post(
            "/dashboard/course/" + this.idCourse + "/all-assistance-register",
            {
              horaryDetailId: this.idDetail,
              assistanceStatusId: this.selected,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            }
          )
          .then((res) => {
            this.$message.success(res.data.content);
            this.$parent.initialize();
            this.$parent.queryAssists();
            this.loader = "";
          })
          .catch((error) => {
            this.$errorQuery(error);
          });
        this.selected = null;
      }
    },
  },
};
</script>